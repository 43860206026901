<template>
  <div>
    <eden-page-header
      :title="'Companies Directory'"
      :subtitle="'Add new company'"
    />
    <company-form v-if="company.id" :action="'edit'" :company="company" />
  </div>
</template>

<script>
import CompanyForm from "@/components/Logistics/Directory/Companies/CompanyForm.vue";

import companies from "@/requests/logistics/directory/companies";

export default {
  name: "CompanyAdd",
  components: { CompanyForm },
  data() {
    return {
      company: {},
    };
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      companies
        .get(this.companyId)
        .then((response) => {
          if (response.data.status) {
            this.company = response.data.data[0];
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
